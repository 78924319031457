.avatars {
  display: flex;
  align-items: center;

  .avatarOverflow {
    border-radius: 18px;
    border: 1px solid #888;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;

    &.doubleDigit {
      font-size: 0.87em;
    }

    &.tripleDigit {
      font-size: 0.78em;
    }
  }
}

.emptyMembers {
  display: flex;
  flex-direction: row;
  align-items: center;

  .message {
    margin-left: 8px;
    font-size: 14px;
    font-style: italic;
  }
}
