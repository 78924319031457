.columnLabelContainer {
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;
  margin-bottom: 8px;

  .nameCol {
    flex: 1;
  }

  .roleCol, .orgCol, .enrollmentCol {
    flex: 1;
    text-align: center;
  }

  .actionsCol {
    width: 64px;
  }
}

.container {
  background-color: white;
  padding: 16px;
  border-radius: 6px;

  .filterContainer {
    margin: 8px 0;
  }

  .createContainer {
    .createButton {
      margin: 16px 0;
      padding: 16px;
      border: 3px dashed #ccc;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #555;

      .addIcon {
        margin-right: 8px;
      }
    }

    .separator {
      margin-top: 24px;
      height: 1px;
      background-color: #eee;
    }
  }

  .courseList {
    .listItem:last-child {
      border: none;
    }
  }
}

.emptyCourseList {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  height: 56px;
}

.footer {
  padding: 0px;
  padding-top: 16px;
  display: flex;
  margin-top: 16px;
  border-top: 1px solid #eee;

  .leftActions {
    flex: 1;
    padding-left: 8px;

    .rowSelect {
      margin-left: 8px;
    }
  }
}