@import 'common/styles/vars.scss';

.container {
  .filterButton {
    height: 32px;
    margin-right: 12px;
    border-color: $bongo-input-border-color;

    &.hasFilters {
      border-color: $bongo-theme-palette-primary;
    }
  }

  .filterIconContainer {
    display: flex;
    justify-content: center;
    width: 24px;

    .filterCountContainer {
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background-color: $bongo-theme-palette-primary;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
}

