.menuItem {
  display: flex;
  flex-direction: row;
  padding: 8px 0;

  .selectedContainer {
    width: 32px;
    height: 32px;
  }

  .contents {
    display: flex;
    flex-direction: column;
  }

  .descriptionContainer {
    font-size: .8rem;
    color: #555;
  }
}