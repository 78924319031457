.container {
  .addButton {
    padding: 16px;
    border: 3px dashed #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #555;
    margin-bottom: 12px;
    margin-top: 10px;

    &.hidden {
      display: none;
    }

    .addIcon {
      margin-right: 8px;
    }
  }

  .inputContainer {
    padding: 16px 0px;
  }

  .addContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .emailContainer {
      min-width: 140px;
      flex: 2;
      display: flex;
      margin-right: 4 px;
      align-items: center;
      justify-content: center;

      .emailInput {
        flex: 1;
      }
    }

    .actionsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4px;

      .progressContainer {
        margin-right: 4px;
      }
    }
  }

  .addError {
    color: #d32f2f;
    margin-top: 2px;
  }
}

.memberItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
  height: 64px;

  .avatar {
    margin-right: 12px;

    @media all and (max-width: 600px) {
      display: none;
    }
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    font-weight: 500;
    overflow: hidden;

    .email {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.nameVisible {
        color: #555;
      }
    }
  }

  .details {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .actions {
    border-radius: 0 6px 6px 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: row;

    @media all and (max-width: 600px) {
      display: none;
    }

    .progressContainer {
      display: flex;
      align-items: center;
    }
  }
}