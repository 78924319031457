.container {
  .addMemberButton {
    padding: 16px;
    border: 3px dashed #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #555;
    margin-bottom: 12px;
    margin-top: 18px;

    &.hidden {
      display: none;
    }

    .addIcon {
      margin-right: 8px;
    }
  }

  .addPaper {
    padding: 4px 4px 8px 4px;
    margin-top: 8px;

    .addContainer {
      margin-top: 4px;
      display: flex;
      align-items: center;
      padding: 8px;

      .emailContainer {
        flex: 2;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;

        .emailInput {
          flex: 1;
        }
      }

      .roleContainer {
        flex: 1;
        display: flex;
      }

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;

        .progressContainer {
          margin-right: 4px;
        }
      }

      @media all and (max-width: 600px) {
        flex-direction: column;

        .emailContainer {
          flex: 1;
          margin: 0;
          width: 100%;
        }

        .roleContainer {
          margin: 0;
          margin-top: 16px;
          align-items: center;
          width: 100%;
        }

        .actionsContainer {
          margin-left: 8px;
        }
      }
    }

    .addError {
      color: #d32f2f;
    }
  }
}
