.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .filterInputContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .filterInput {
      max-width: 300px;
    }
  }
}
