@import 'common/styles/vars.scss';

.visibilityContainer,
.overflowContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 16px 0;
}

.visibilityContainer {
  align-items: center;
}

.overflowContainer {
  margin-left: 8px;
}

.actionsContainer {
  padding: 0 8px;
  display: flex;

  .visibleBtn {
    &.inErrorState {
      visibility: hidden;
    }
  }
}
