@import 'common/styles/vars.scss';

.item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  min-height: 72px;
  overflow: hidden;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: #f7f7f7;

    .contents {
      .nameContainer,
      .typeContainer {
        .label {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.35s ease-in;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex: 1;

    .nameContainer,
    .typeContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 16px 0;

      .errorContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;
        font-size: 13px;

        .errorStateIcon {
          color: $bongo-error-color;
          margin-right: 6px;
        }

        .errorMessage {
          flex: 1;
        }
      }

      .label {
        display: flex;
        font-size: 12px;
        line-height: 12px;
        position: absolute;
        top: 0;
        visibility: hidden;
        opacity: 0;
      }
    }

    .nameContainer {
      overflow: hidden;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .sep {
    height: 2px;
    background-color: #ccc;
  }

  .details {
    flex: 1;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 8px;

    // &.disabled {
    //   opacity: 1;
    // }

    .name {
      flex: 1;
    }

    .role,
    .org {
      flex: 1;
    }
  }

  @media screen and (max-width: 600px) {
    .contents {
      .details {
        grid-template-columns: 1fr;

        .typeContainer {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .contents {
      .details {
        // &.canAdminister {
          grid-template-columns: 1fr;

          .typeContainer {
            display: none;
          }
        // }
      }
    }
  }
}
