@import 'common/styles/vars.scss';

.container {
  .sortButton,
  .sortDirection,
  .sortField {
    height: 32px;
    border-color: $bongo-input-border-color;
  }

  .sortField {
    white-space: nowrap;
  }

  .sortDirection {
    width: 24px;
    padding: 0;
  }
}
