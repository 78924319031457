.confirmOverlayContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  background-color: #ecf8ff;

  .confirmMessage {
    margin-right: 16px;
  }

  .confirmButton {
    margin-right: 8px;
    background-color:#ecf8ff
  }
}