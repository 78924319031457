.container {
  min-width: 400px;
  padding: 8px;

  @media screen and (max-width: 480px) {
    min-width: unset;
    padding: 0;
  }

  .memberList {
    max-height: 260px;
    overflow: auto;
    padding: 4px;
  }
}