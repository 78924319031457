.dialogContent {
  padding: 16px;

  .content {
    padding: 0 16px;
  }

  .nameContent {
    margin-top: 8px;
  }

  .activityLoader {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activityContent {
    margin-top: 24px;

    .listHeader {
      display: flex;
      align-items: center;

      .name {
        flex: 1;
      }
    }

    .activityList {
      padding-top: 8px;
    }
  }

  .separator {
    margin: 24px 0 4px 0;
    height: 1px;
    background-color: #eee;
  }
}