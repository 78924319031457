// @import 'common/styles/vars.scss';

.container {
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  // box-shadow: $shadow-smallest;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }

  @media screen and (max-width: 480px) {
    padding: 8px;
  }

  .header {
    margin-bottom: 8px;
    display: flex;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    flex: 1;

    .title {
      margin: 0;
      font-weight: 500;
    }

    .secondaryTitle {
      margin-left: 8px;
    }
  }
}
