.memberList {
  .memberItem:last-child {
    border: none;
  }
}

.listActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .filterField {
    margin-right: 16px;
    min-width: 260px;
  }

  .sortContainer {
    display: flex;
    align-items: center;

    .filterIcon {
      margin-right: 8px;
    }
  }
}

.footer {
  display: flex;
  margin-top: 16px;
  align-items: center;

  .leftActions {
    flex: 1;
  }

  .rightActions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
