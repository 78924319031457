.page {
  .container {
    height: calc(100vh - 64px);
  }

  .sep {
    width: 1px;
    background-color: #ccc;
    height: 18px;
    margin: 0 12px;
  }

  .courseDetails {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 16px 0;

    .memberInfo {
      display: flex;
      align-items: center;
    }
  }
}
