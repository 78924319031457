.filter {
  .filterIcon {
    margin-right: 8px;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 14px;
  }
}

.clearBtn {
  &.hidden {
    display: none;
  }
}
