.container {
  display: flex;
  align-items: center;

  .copyIcon {
    color: white;
  }

  .statusButton {
    margin-right: 8px;
    padding: 0;
  }
}

.separator {
  height: 34px;
  width: 1px;
  background-color: white;
  margin-left: 8px;
}

.completedIcon {
  position: absolute;
  right: 9px;
  bottom: 0;
}

.progressContainer {
  min-width: 200px;
  padding: 8px;

  .item {
    display: flex;
    align-items: center;
    padding: 16px;

    .details {
      display: flex;
      flex-direction: column;
      margin-left: 18px;

      .name {
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
      }

      .status {
        color: #555;
        font-size: 14px;
      }
    }
  }
}
