.filterContainer {
  padding: 12px;
  min-width: 200px;

  .header {
    display: flex;
    align-items: center;

    .title {
      flex: 1;
    }
  }

  .headerDivider {
    margin-top: 8px;
  }

  .filterInput {
    margin-top: 8px;
  }

  .filterSection {
    margin-top: 8px;

    .filterSectionContent {
      margin-left: 16px;
    }
  }
}
