// These css vars match bongo defaults, but wouldn't have to.
// They could be passed through in the launch parameters to enable standalone themes.
:root {
    // --bsa-theme-palette-primary: #2679c7;
    --bsa-theme-palette-primary: #35AFEF;
    --bsa-theme-palette-primary-contrast: #ffffff;
    --bsa-theme-palette-secondary: #c92861;
    --bsa-theme-palette-secondary-contrast: #ffffff;

    --bsa-theme-color-button-disabled-background: #e0e0e0;
    --bsa-theme-color-button-disabled-contrast: #757575;
    --bsa-theme-color-button-enabled-background: #2679c7;
    --bsa-theme-color-button-enabled-contrast: #ffffff;
    --bsa-theme-color-page-background: #f2f3f5;
    --bsa-theme-color-page-toolbar-contrast: # ffffff;
    --bsa-theme-color-page-contrast: #646464;

    --bsa-theme-font-family: Roboto, Arial, Helvetica, sans-serif;
    --bsa-theme-font-page-h1-size: 42px;
}

$bongo-theme-palette-primary: #2679c7;
$bongo-input-border-color: #ccc;
$bongo-page-gradient: linear-gradient(43deg, #00adef 30%, #0e82d8 56%, #1d25aa 100%);
$bongo-default-color: #555;
$bongo-error-color: #c2524d;

$shadow-smallest: 0px 2px 4px rgba(0,0,0,.1);
$shadow-extra-small: 0px 4px 8px rgba(0,0,0,.12);
$shadow-small: 0 5px 10px rgba(0,0,0,.12);
$shadow-medium: 0 8px 30px rgba(0,0,0,.12);
$shadow-large: 0 30px 60px rgba(0,0,0,.12);
$shadow-hover: 0 30px 60px rgba(0,0,0,.12);

// --accents-1: #fafafa;
// --accents-2: #eaeaea;
// --accents-3: #999;
// --accents-4: #888;
// --accents-5: #666;
// --accents-6: #444;
// --accents-7: #333;
// --accents-8: #111;

// $bongo-font-icon-size: 20px;
// $bongo-button-icon-size: $bongo-font-icon-size * 2;
// $bongo-button-icon-padding: $bongo-button-icon-size / 4;
// $bongo-desktop-min-width: 1025px;
// $bongo-color-blue: #2679c7;
// $bongo-primary-text-color: rgba(0, 0, 0, .87);
// $bongo-color-required-text-red: #f44336;
// $bongo-toolbar-height: 56px;

// //Secondary Colors
// $bongo-color-secondary-orange: #e87511;
// $bongo-color-secondary-orange-highlight: #fae3cf;
// $bongo-color-secondary-yellow: #ffcc4e;
// $bongo-color-secondary-yellow-shadow: #d7a412;
// $bongo-color-secondary-gray-dark: #717c83;
// $bongo-color-secondary-gray-light: #c4cdd3;

// //Icon Colors
// $bongo-color-avatars-green: #6eac52;
// $bongo-color-avatars-orange: #ff7d34;
// $bongo-color-avatars-red: #d5122C;


// //Icon Colors (with level-aaa contrast from #ededee)
// $bongo-color-player-slider-aaa-on-light-gray: #21592a;
